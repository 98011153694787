import React from 'react'
import PropTypes from 'prop-types'

const Bodytext = props => {
  const { html, theme } = props

  return (
    <React.Fragment>
      <div
        className="post__content typography"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </React.Fragment>
  )
}

Bodytext.propTypes = {
  html: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Bodytext
