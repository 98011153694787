import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Article from '../components/Article'
import Page from '../components/Page'
import PageHeader from '../components/Shared/PageHeader'
import StoreContext, { defaultStoreContext } from '../context/StoreContext'
// import { ThemeContext } from "../layouts";

const PageTemplate = props => {
  const {
    data: {
      page,
      site: {
        siteMetadata: { facebook },
      },
    },
  } = props

  const breadcrumb = [{ title: 'Home', url: '' }, { title: 'Page', url: '' }]

  // console.log(page)

  return (
    <React.Fragment>
      <PageHeader header={page.frontmatter.title} breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <StoreContext.Consumer>
                {theme => (
                  <Article theme={theme}>
                    <Page page={page} theme={theme} />
                  </Article>
                )}
              </StoreContext.Consumer>
            </div>
          </div>
        </div>
      </div>

      <SEO data={page} facebook={facebook} />
    </React.Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageTemplate

//eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query PageByPath($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        cover {
          childImageSharp {
            resize(width: 300) {
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`
